module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"OpenSIT","short_name":"OpenSIT","description":"All SAP Inside Track Sessions in one place.","lang":"en","start_url":"/","background_color":"#ffffff","display":"standalone","icon":"src/images/opensit-favicon_400x400.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"7d462aa645522e7201dbd86a23cf87bc"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
